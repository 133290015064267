import { sendUtagEvent } from '../../core/analytics/utag_events'
import { cleaned_title } from '../../utils/utilities'

export function initializeUtagEvents(gallery) {
  const fhiPageType = document.querySelector('html').dataset.fhiTest
  IB.VisitorSessionId.initNewVisitorSessionId()

  gallery.querySelectorAll('.js-gallery-videos .hotel-gallery__slider .is-video').forEach(photoVideoElement => {
    photoVideoElement.addEventListener('click', () => {
      const name = 'hotel_multimedia_photos'
      const cat = 'hotel information'
      const act = 'see multimedia info'
      const lbl = 'photos'
      const purpose = 'inspiration'
      const structure = 'popup'
      sendUtagEvent({
        data: {
          event_name: name,
          event_cat: cat,
          event_act: act,
          event_lbl: lbl,
          event_purpose: purpose,
          event_structure: structure,
          page_variation: fhiPageType,
          page_images_clicked: '1',
        },
      })
    })
  })
  gallery.querySelectorAll('.js-gallery-section .hotel-gallery__grid .is-tour').forEach(photoVideoElement => {
    photoVideoElement.addEventListener('click', () => {
      const name = 'hotel_multimedia_photos'
      const cat = 'hotel information'
      const act = 'see multimedia info'
      const lbl = 'photos'
      const purpose = 'inspiration'
      const structure = 'popup'
      sendUtagEvent({
        data: {
          event_name: name,
          event_cat: cat,
          event_act: act,
          event_lbl: lbl,
          event_purpose: purpose,
          event_structure: structure,
          page_variation: fhiPageType,
          page_images_clicked: '1',
        },
      })
    })
  })

  document.querySelector('.utag-floating-fastbooking')?.addEventListener(
    'click',
    () => {
      event_name = isDestiniaForm() ? 'searcher_flight_hotel' : 'searcher_hotel'
      event_cat = isDestiniaForm() ? 'searcher fly' : 'searcher'
      event_act = 'scroll to fastbooking'
      event_purpose = 'booking'
      event_lbl = 'popup'
      event_structure = 'popup'
      page_variation = getPageVariation()
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
          page_variation,
        },
      })
    },
    { once: true }
  )
  gallery
    .querySelectorAll('.js-gallery-section  .hotel-gallery__grid .hotel-gallery__image-wrap')
    .forEach(photoVideoElement => {
      photoVideoElement.addEventListener('click', () => {
        const name = 'hotel_multimedia_photos'
        const cat = 'hotel information'
        const act = 'see multimedia info'
        const lbl = 'photos'
        const purpose = 'inspiration'
        const structure = 'popup'
        sendUtagEvent({
          data: {
            event_name: name,
            event_cat: cat,
            event_act: act,
            event_lbl: lbl,
            event_purpose: purpose,
            event_structure: structure,
            page_variation: fhiPageType,
            page_images_clicked: '1',
          },
        })
      })
    })
  gallery.querySelectorAll('.utag-swiper-button-next, .utag-swiper-button-prev').forEach(arrowButtons => {
    arrowButtons.addEventListener('click', () => {
      const pageVariation = document.querySelector('html').dataset.fhiTest === undefined ? 'rational' : 'inmersive'
      sendUtagEvent({
        data: {
          event_name: 'hotel_multimedia_photos',
          event_cat: 'hotel information',
          event_act: 'see multimedia info',
          event_lbl: 'photos',
          event_purpose: 'inspiration',
          event_structure: 'popup',
          page_variation: pageVariation,
        },
      })
    })
  })

  // BOTTOM SHEET UTAG EVENT (MOBILE)
  const shareButton = gallery.querySelector('.js-utag-share')
  shareButton?.addEventListener('click', e => {
    const hotelName = utag_data.hotel_name !== undefined ? cleaned_title(utag_data.hotel_name) : ''

    sendUtagEvent({
      data: {
        event_name: 'share_hotel',
        event_cat: 'hotel_information',
        event_act: 'share hotel',
        event_lbl: hotelName,
        event_structure: 'sticky',
        event_purpose: 'inspiration',
      },
    })
  })
}
