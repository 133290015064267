import { addImgToObserver } from '../../core/iberapi/lazy_img/lazy_img'
import { loadComponentAssets } from '../../lazy_wrappers/lazy_assets_loader'
import BottomSheet from '../../shared/bottom_sheet/bottom_sheet'
import loaderSpinner from '../../shared/loader_spinner/loader_spinner'
import { copyToClipboardNew } from '../../utils/copy_to_clipboard'

import { initializeUtagEvents } from './hotel_gallery.analytics'
import { bindEvents, scrollTo } from './hotel_gallery.commons'

export function openGallery(afterShowCallback) {
  const galleryImages = document.querySelector('.js-hotel-gallery')

  if (!galleryImages) {
    return
  }

  const fancyBoxOpen = document.querySelector('.fancybox-is-open')
  if (fancyBoxOpen) return

  loaderSpinner.show()

  initializeUtagEvents(galleryImages)

  loadComponentAssets(galleryImages).then(() => {
    loaderSpinner.hide()
    galleryImages.classList.remove('hidden')

    $.fancybox.open(galleryImages, {
      autoFocus: false,
      fullScreen: {
        requestOnStart: true,
      },
      afterShow(instance, current) {
        ScrollLock.on()
        const $lightbox = current.$content
        // Add lazy loading behaviour inside lightbox
        addImgToObserver($lightbox)

        bindEvents($lightbox)

        afterShowCallback && afterShowCallback($lightbox)

        const buttonClose = galleryImages.querySelector('.fancybox-button.fancybox-close-small')

        if (buttonClose) {
          buttonClose.remove()
        }
      },
      afterClose() {
        ScrollLock.off()
      },
    })
  })
}

export function init() {
  const fancyboxPhotos = document.querySelectorAll('.js-open-gallery-modal')

  fancyboxPhotos.forEach(photos => {
    photos.addEventListener('click', function (e) {
      e.preventDefault()

      let galleryTagId
      const imageId = this.dataset.id
      if ('galleryTagId' in photos.dataset) {
        galleryTagId = e.target.closest('.js-fancybox-photos').dataset.galleryTagId
      }

      openGallery($lightbox => {
        if (galleryTagId) {
          scrollTo($lightbox, `#gallery-tag-${galleryTagId}`)
        } else if (imageId) {
          scrollTo($lightbox, `#gallery-image-${imageId}`)
        }
      })
    })
  })
  const socialShareList = document.querySelector('.social-share-list')
  const btnShareSocial = document.querySelector('.js-show-sheet')
  function showSocialListMobileHotelGallery() {
    btnShareSocial?.addEventListener('click', function () {
      socialShareList.classList.add('visible')
    })
  }
  showSocialListMobileHotelGallery()
  // Instantiate the class and init
  const showSheetBtn = document.querySelector('.js-show-sheet:not([data-bottom-sheet-initialized])')
  if (showSheetBtn) {
    const bottomSheet = new BottomSheet({
      showButton: showSheetBtn,
    })
  }

  // Copy url to clipboard function for bottom sheet link
  function initCopytoClipBoard() {
    const copyUrlbutton = document.querySelector('.js-copy-to-clipboard')
    copyUrlbutton?.addEventListener('click', e => {
      const url = window.location
      copyToClipboardNew(url)
    })
  }
  initCopytoClipBoard()
}
