/**
 * Represents a bottom sheet UI component.
 */

export default class BottomSheet {
  /**
   * @param {Object} config - The configuration object for the bottom sheet.
   * @param {HTMLElement} config.showButton - The button that shows the bottom sheet.
   * @param {number} [config.sheetHeight] - 'auto' by default. The initial height of the bottom sheet (in vh).
   * @throws {Error} Will throw an error if the 'showButton' parameter is not provided.
   * @throws {Error} Will throw an error if the 'sheetSelector' dataset is not provided.
   */

  constructor(config = {}) {
    if (!config.showButton) {
      throw new Error("The 'showButton' parameter is required")
    }

    config.sheetHeight = config.sheetHeight || 'auto'

    this.config = config

    // Select DOM elements
    this.showModalBtn = config.showButton
    const sheetSelector = this.showModalBtn.dataset.sheetSelector

    if (!sheetSelector) {
      throw new Error("The 'sheetSelector' dataset is required")
    }
    this.bottomSheet = document.querySelector(sheetSelector)
    this.sheetOverlay = this.bottomSheet.querySelector('.js-sheet-overlay')
    this.sheetContent = this.bottomSheet.querySelector('.js-sheet-content')
    this.dragIcon = this.bottomSheet.querySelector('.js-drag-icon')

    // Global variables for tracking drag events
    this.isDragging = false
    this.startY = 0
    this.startHeight = 0

    // Default sheet height
    this.sheetHeight = this.setDefaultSheetHeight()
    this.initialSheetHeight = this.sheetHeight
    this.sheetHeightLowerLimit = this.sheetHeight / 2

    // Initialize
    this.init()

    this.showModalBtn.dataset.bottomSheetInitialized = 'true'
  }

  setDefaultSheetHeight() {
    if (this.config.sheetHeight === 'auto') {
      this.sheetContent.style.height = 'auto'
      const currentSheetHeight = Math.ceil((this.sheetContent.clientHeight * 100) / window.innerHeight)
      this.sheetHeight = Math.min(currentSheetHeight, 100) // Set sheet height to current height or 100vh, whichever is smaller
    } else {
      this.sheetHeight = this.config.sheetHeight || 50
    }
    this.initialSheetHeight = this.sheetHeight
    this.sheetHeightLowerLimit = this.sheetHeight / 2
  }

  // Show the bottom sheet, hide body vertical scrollbar, and call updateSheetHeight
  showBottomSheet() {
    this.bottomSheet.classList.add('is-visible')
    this.setDefaultSheetHeight()
    this.updateSheetHeight(this.sheetHeight)
    ScrollLock.on()
  }

  updateSheetHeight(height) {
    this.sheetContent.style.height = `${height}vh` //updates the height of the sheet content
    // Toggles the fullscreen class to bottomSheet if the height is equal to 100
    this.bottomSheet.classList.toggle('is-fullscreen', height === 100)
  }

  // Hide the bottom sheet and show body vertical scrollbar
  hideBottomSheet() {
    this.bottomSheet.classList.remove('is-visible')
    ScrollLock.off()
  }

  // Sets initial drag position, sheetContent height and add dragging class to the bottom sheet
  dragStart(e) {
    this.isDragging = true
    this.startY = e.pageY || e.touches?.[0].pageY
    this.startHeight = parseInt(this.sheetContent.style.height)
    this.bottomSheet.classList.add('is-dragging')
  }

  // Calculates the new height for the sheet content and call the updateSheetHeight function
  dragging(e) {
    if (!this.isDragging) return
    const delta = this.startY - (e.pageY || e.touches?.[0].pageY)
    const newHeight = this.startHeight + (delta / window.innerHeight) * 100
    this.updateSheetHeight(newHeight)
  }

  // Determines whether to hide, set to fullscreen, or set to default
  // height based on the current height of the sheet content
  dragStop() {
    if (!this.isDragging) return
    this.isDragging = false
    this.bottomSheet.classList.remove('is-dragging')
    const sheetHeight = parseInt(this.sheetContent.style.height)
    if (sheetHeight < this.startHeight) {
      if (this.startHeight === this.initialSheetHeight) {
        this.hideBottomSheet()
      } else {
        this.updateSheetHeight(this.initialSheetHeight)
      }
    } else {
      this.updateSheetHeight(100)
    }
  }

  addEventListeners() {
    this.dragIcon.addEventListener('mousedown', this.dragStart.bind(this))
    document.addEventListener('mousemove', this.dragging.bind(this))
    document.addEventListener('mouseup', this.dragStop.bind(this))

    this.dragIcon.addEventListener('touchstart', this.dragStart.bind(this))
    document.addEventListener('touchmove', this.dragging.bind(this))
    document.addEventListener('touchend', this.dragStop.bind(this))

    this.sheetOverlay.addEventListener('click', this.hideBottomSheet.bind(this))
    this.showModalBtn.addEventListener('click', this.showBottomSheet.bind(this))
  }

  init() {
    this.addEventListeners()
  }
}
