/**
 * Observes an element's visibility in viewport.
 *
 * @param {*} elem
 * @param {*} callback - function to execute when it detects that an element's visibility has crossed one or more thresholds
 * @param {*} options - see available options on https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 *
 * @example
  inViewport('.target', (element) => {
    console.log(`Element is visible? ${element.isIntersecting}`)
  }, {
    root: document.querySelector('.scroll')
  })
 *
 */
const inViewport = (elem, callback, options = {}) => {
  const observerInstance = new IntersectionObserver(entries => {
    entries.forEach(entry => callback(entry))
  }, options)
  observerInstance.observe(elem)
  return observerInstance
}

export default inViewport
