export function copyToClipboardNew(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method
    return navigator.clipboard.writeText(textToCopy)
  }
  // text area method
  const textArea = document.createElement('textarea')
  textArea.value = textToCopy
  // make the textarea out of viewport
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((res, rej) => {
    document.execCommand('copy') ? res() : rej()
    textArea.remove()
  })
}

/**
 * Handles the click event for copying text to clipboard.
 * @param {Event} event The click event object.
 */
export function handleCopyToClipboardClick(event) {
  const content = event.target
  const text = event.target.innerText
  const textCopiedTranslation = event.target.getAttribute('data-translations')

  // Call function to copy text to clipboard
  copyToClipboardNew(text).then(() => {
    // Replace content text with copied text translation temporarily
    content.innerText = textCopiedTranslation

    // Restore original content text after 3 seconds
    setTimeout(() => {
      content.innerText = text
    }, 3000)
  })
}
